
.timeline {
  position: relative;
}
.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 1rem;
  height: 100%;
  border-right: 2px solid #e9ecef;
}
[data-timeline-axis-style="dashed"]:before {
  border-right-style: dashed !important;
}
[data-timeline-axis-style="dotted"]:before {
  border-right-style: dotted !important;
}
.timeline-block {
  position: relative;
  margin: 2em 0;
}
.timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.timeline-block:first-child {
  margin-top: 0;
}
.timeline-block:last-child {
  margin-bottom: 0;
}
.timeline-step {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  text-align: center;
  transform: translateX(-50%);
  font-size: 1rem;
  font-weight: bold;
  z-index: 1;
}
.timeline-step svg, .timeline-step i {
  line-height: 1.4;
}
.timeline-step-icon {
  background: #fff;
  border: 2px solid #e9ecef;
}
.timeline-step-xs {
  width: 17px;
  height: 17px;
  font-size: 0.75rem;
}
.timeline-step-sm {
  width: 23px;
  height: 23px;
  font-size: 0.75rem;
}
.timeline-step-lg {
  width: 47px;
  height: 47px;
  font-size: 1.75rem;
}
.timeline-content {
  position: relative;
  margin-left: 60px;
  padding-top: 0.5rem;
  position: relative;
  top: -6px;
}
.timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.timeline-body {
  padding: 1.5rem;
}
.timeline-one-side:before {
  left: 1rem;
}
.timeline-one-side .timeline-step {
  left: 1rem;
}
.timeline-one-side .timeline-content {
  width: auto;
}
.timeline-one-side .timeline-block:nth-child(even) .timeline-content {
  float: none;
}
.custom-toggle-default input:checked + .custom-toggle-slider
{
    border-color: #172b4d;
}
.custom-toggle-default input:checked + .custom-toggle-slider:before
{
    background: #172b4d;
}
.custom-toggle-default input:checked + .custom-toggle-slider:after
{
    color: #172b4d;
}

.custom-toggle-default input:disabled:checked + .custom-toggle-slider
{
    border-color: #172b4d;
}
.custom-toggle-default input:disabled:checked + .custom-toggle-slider:before
{
    background-color: #234174;
}
